import React from "react"
import styled from "styled-components"
import Donut from "@/components/Chart/Donut"
import Bar from "@/components/Chart/Bar"
import StackedBar from "@/components/Chart/StackedBar"
import { withLanguage } from "@/utils/i18n"
import { useTranslation } from "react-i18next"
import CentreContainer from "@/components/Container"
import { makeStyles } from "@material-ui/core/styles"
import chartConfig from "@/config/charts.json"

const StyledChartWrapper = styled.div`
  ${({ theme, span }) => `
  padding: ${theme.spacing(2)}px;
  grid-column: ${span === 2 ? `1 / -1` : "auto"};

  .chart__title {
      font-family: "Noto Serif TC", serif;
      font-size: 1.5rem;
      font-weight: 900;
      margin-bottom: 10px;
  }

  .chart__main {
    margin: ${theme.spacing(2)}px auto 0;
    width: 100%;
  }

  .chart__remarks {
    margin-top: ${theme.spacing(1)}px;
    color: ${theme.palette.secondary.main};
  }
 `}
`

function Chart({ chart }) {
  const { i18n, t } = useTranslation()
  const title = withLanguage(i18n, chart, "title")
  const subTitle = withLanguage(i18n, chart, "subtitle")
  const description = withLanguage(i18n, chart, "description")
  const subTitleArray = subTitle && subTitle.split("_")
  const remarks = withLanguage(i18n, chart, "remarks")

  return (
    <StyledChartWrapper span={chart.span}>
      <div className="chart__title">{title}</div>
      {description && <div className="chart__description">{description}</div>}
      <div className="chart__main">
        {chart.type === "donut" && (
          <Donut
            data={chart.data.map(d => ({
              ...d,
              label: withLanguage(i18n, d, "label"),
              value: +d.value,
            }))}
            unit={withLanguage(i18n, chart, "unit")}
            subTitle={subTitleArray.length ? subTitleArray[1] : subTitle}
            staticNumber={subTitleArray.length && subTitleArray[0]}
            t={t}
          />
        )}
        {chart.type === "bar" && (
          <Bar
            data={chart.data.map(d => ({
              ...d,
              label: withLanguage(i18n, d, "label"),
            }))}
            unit={withLanguage(i18n, chart, "unit")}
            t={t}
          />
        )}
        {chart.type === "stacked_bar" && (
          <StackedBar
            data={chart.data.map(d => ({
              ...d,
              value: +d.value,
              label: withLanguage(i18n, d, "label"),
            }))}
            unit={withLanguage(i18n, chart, "unit")}
            t={t}
          />
        )}
      </div>
      {remarks && <div className="chart__remarks">{remarks}</div>}
    </StyledChartWrapper>
  )
}

const ChartContainer = React.memo(function ChartContainer() {
  const classes = useStyles()
  return (
    <div className={classes.fullWidthSecondary}>
      <CentreContainer>
        <div className={classes.gridWrapper}>
          {chartConfig.map(chart => {
            return <Chart key={chart.name} chart={chart} />
          })}
        </div>
      </CentreContainer>
    </div>
  )
})

export default ChartContainer

const useStyles = makeStyles(theme => ({
  fullWidthSecondary: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    background: theme.palette.background.paper,
  },
  gridWrapper: {
    display: "grid",
    gridGap: theme.spacing(3),
    width: "100%",
    // https://github.com/recharts/recharts/issues/1423#issuecomment-425646541
    gridTemplateColumns: `100%`,
    [theme.breakpoints.up("viewport7")]: {
      gridTemplateColumns: `50% 50%`,
    },
  },
}))
