import React from "react"
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts"
import styled from "styled-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const size = 16
const StyledWrapper = styled.div`
  ${({ theme, isMobile }) => `
    width: 100%;

    .legend {
      margin-top: ${theme.spacing(2)}px;
      display: ${isMobile ? "block" : "flex"};
      flex-wrap: ${isMobile ? "inherit" : "wrap"};
    }

    .legend-item {
      margin-bottom: ${theme.spacing(1)}px;
      margin-right: ${isMobile ? 0 : `${theme.spacing(6)}px`};
    }

    .legend-item-dot {
      display: flex;
      align-items: center;

      svg {
        margin-right: ${theme.spacing(0.5)}px;
      }
    }
  `}
`

export default function HorizontalStackedBarChart({ data, unit }) {
  const chartData = {}
  data.forEach(d => (chartData[d.label] = d.value))
  const isMobile = !useMediaQuery("(min-width: 600px)")

  return (
    <StyledWrapper isMobile={isMobile}>
      <ResponsiveContainer width="100%" height={30}>
        <BarChart
          data={[chartData]}
          layout="vertical"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <XAxis type="number" hide />
          <YAxis hide type="category" />
          {data.map(d => (
            <Bar
              key={d.label}
              dataKey={d.label}
              stackId="a"
              fill={d.color}
              value={d.value}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div className="legend">
        {data.map((d, i) => (
          <div key={i} className="legend-item">
            <div className="legend-item-dot">
              <svg
                style={{
                  height: size,
                  width: size,
                }}
              >
                <circle
                  cx={size / 2}
                  cy={size / 2}
                  r={size / 2}
                  fill={d.color}
                />
              </svg>
              <span>
                {d.value}
                {unit}
              </span>
            </div>
            <div>{d.label}</div>
          </div>
        ))}
      </div>
    </StyledWrapper>
  )
}
