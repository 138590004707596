import React from "react"
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts"
import styled from "styled-components"

const StyledWrapper = styled.div`
  ${({ theme }) => `

  display: flex;
  flex-wrap: wrap;
  font-family: "Noto Serif TC", serif;

  .recharts-wrapper {
    margin: 0 auto;
  }

  .chart__legend {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacing(1)}px;
    margin: ${theme.spacing(1)}px;
  }

  .chart__legendItem {
    display: flex;
    justify-content: center;
  }

  .chart__legendIndicator {
    padding-right: ${theme.spacing(0.5)}px;
  }

  .chart__legendValue {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .chart__legendName {
    font-size: 0.75rem;
    font-weight: 700;
    color: black;
  }
  `}
`

export default function Donut({ data, subTitle, staticNumber }) {
  const sum = staticNumber || data.reduce((a, c) => a + +c.value, 0)
  const r = 150
  return (
    <ResponsiveContainer>
      <StyledWrapper>
        <PieChart
          width={300}
          height={300}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <text
            x={r}
            y={r / 2}
            dy={r / 2}
            textAnchor="middle"
            style={{
              fontSize: "3rem",
              fontWeight: 700,
              fill: "black",
            }}
          >
            {sum}
          </text>
          <text
            x={r}
            y={r / 2}
            dy={r / 2 + 24}
            textAnchor="middle"
            style={{
              fontSize: "0.8rem",
              fontWeight: 700,
              fill: "black",
            }}
          >
            {subTitle}
          </text>
          <Pie
            data={data}
            innerRadius={r * 0.8}
            outerRadius={r}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="chart__legend">
          {data.map(d => {
            return (
              <div key={d.label}>
                <div className="chart__legendItem">
                  <div className="chart__legendIndicator">
                    <svg
                      style={{
                        height: 8,
                        width: 8,
                      }}
                    >
                      <circle cx={4} cy={4} r={4} fill={d.color} />
                    </svg>
                  </div>
                  <div>
                    <div className="chart__legendValue">
                      {staticNumber
                        ? d.value
                        : ((d.value / sum) * 100).toFixed(2)}
                      %
                    </div>
                    <div className="chart__legendName">{d.label}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </StyledWrapper>
    </ResponsiveContainer>
  )
}
