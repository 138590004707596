import React, { useState, useEffect } from "react"
import NameCard, { NameCardPlaceholder } from "@/components/Swiper/NameCard"
import randomDefendantListCount from "@/config/randomDefendantListCount.json"
import Loader from "@/components/Loader"

const RandomCard = () => {
  const [defendants, setDefendants] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const randomId = Math.floor(
        Math.random() * randomDefendantListCount.count
      )
      let data = await fetch(
        `${window.location.origin}/data/randomDefendants/${randomId}.json`
      ).then(responses => responses.json())

      // Randomize the defendant array
      for (let i = data.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = data[i]
        data[i] = data[j]
        data[j] = temp
      }

      setDefendants(
        data.map(d => d.pop().pop()).filter(d => d) // Ensure all defendants are associated with case
      )
      setIsLoading(false)
    }

    fetchData()
  }, [])

  if (isLoading) {
    return (
      <NameCardPlaceholder>
        <Loader minHeight={271} />
      </NameCardPlaceholder>
    )
  }

  return (
    <>
      <NameCard defendants={defendants} />
    </>
  )
}

export default React.memo(RandomCard)
