import React, { useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import Tab from "@/components/Tab"
import ContextStore from "@/contextStore"
import IncidentTab from "@/IncidentTab"
import ChargeTab from "@/ChargeTab"
import { trackCustomEvent } from "@/utils/ga"

const Tabs = ({ charges, incidents, case_person_status, isLoading }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [activeTabId, setActiveTabId] = useState(0)

  const {
    stickyCard: { dispatch },
  } = useContext(ContextStore)

  const tabs = [
    {
      title: t("title.incident"),
      content: (
        <IncidentTab
          incidents={incidents}
          case_person_status={case_person_status}
          isLoading={isLoading}
        />
      ),
    },
    {
      title: t("title.charge"),
      content: (
        <ChargeTab
          charges={charges}
          case_person_status={case_person_status}
          isLoading={isLoading}
        />
      ),
    },
  ]

  return (
    <div className={classes.tabWrapper}>
      <Tab
        tabs={tabs}
        passActiveId={id => {
          if (id !== activeTabId) {
            dispatch({ type: null })
            setActiveTabId(id)
            trackCustomEvent("Click", {
              event_category: "Tab",
              event_label: `Landing - ${tabs[id].title}`,
            })
            //setFilteredIncidents(incidentsWithPureName)
            //setFilteredCharges(chargesWithPureName)
          }
        }}
      />
    </div>
  )
}

export default Tabs

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    marginTop: theme.spacing(3),
    background: theme.palette.background.paper,
  },
}))
