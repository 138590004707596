import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import RandomCard from "@/components/RandomCard"

const Intro = React.memo(props => {
  const { images } = props
  const landingImage = images.find(image => image.key === "LANDING_IMAGE")
  const classes = useStyles({
    backgroundImage:
      landingImage && landingImage.image_url.replace("raw", "large"),
  })

  return (
    <>
      <div className={classes.introWrapper}>
        <div className={classes.intro}>
          <RandomCard />
        </div>
      </div>
    </>
  )
})

const useStyles = makeStyles(theme => ({
  introWrapper: props => ({
    width: "100%",
    backgroundImage: `linear-gradient(rgba(8, 8, 8, 0), rgba(8, 8, 8, 0.1) 100%, ${theme.palette.primary.contrastText} 100%), url(${props.backgroundImage})`,
    [theme.breakpoints.up("viewport7")]: {
      backgroundImage: `linear-gradient(rgba(8, 8, 8, 0), rgba(8, 8, 8, 0.3) 100%, ${theme.palette.primary.contrastText} 100%), url(${props.backgroundImage})`,
    },
    backgroundSize: "cover",
    paddingTop: theme.spacing(6),
  }),
  intro: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("viewport7")]: {
      width: "768px",
    },
    [theme.breakpoints.up("viewport9")]: {
      width: "992px",
    },
  },
  introTitle: {
    margin: "0 auto",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    maxWidth: "360px",
    height: "70px",
    [theme.breakpoints.up("viewport7")]: {
      height: "100px",
      maxWidth: "600px",
    },
  },
  mainIntroTitle: {
    paddingTop: theme.spacing(1.5),
    color: theme.palette.secondary.main,
    fontFamily: "Noto Serif TC, serif",
    fontWeight: 700,
    wordWrap: "anywhere",
    fontSize: "1.1rem",
    [theme.breakpoints.up("viewport7")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("viewport9")]: {
      fontSize: "1.5rem",
    },
  },
  mainIntro: {
    "& a": {
      fontWeight: 700,
      color: theme.palette.primary.contrastText,
      textDecoration: "none",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    fontSize: "1.05rem",
    wordWrap: "anywhere",
  },
}))

export default Intro
