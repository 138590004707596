import React from "react"
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Label,
} from "recharts"
import styled from "styled-components"

const StyledWrapper = styled.div`
  width: 100%;
  height: 200px;
`

const renderCustomizedLabel = props => {
  // content shouldn't be passed into <Label />
  // eslint-disable-next-line no-unused-vars
  const { content, ...rest } = props
  return <Label {...rest} fontSize="14" fill="#FFFFFF" />
}

export default function HorizontalBarChart({ data }) {
  return (
    <StyledWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <XAxis type="number" hide />
          <YAxis
            tickLine={false}
            axisLine={false}
            width={100}
            type="category"
            dataKey="label"
            style={{
              fill: "black",
            }}
          />
          <Bar
            dataKey="value"
            barSize={20}
            style={{
              fill: data[0].color,
            }}
          >
            <LabelList
              dataKey="value"
              position="insideRight"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </StyledWrapper>
  )
}
