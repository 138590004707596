import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { withLanguage } from "@/utils/i18n"
import LatestUpdates from "@/components/Swiper/LatestUpdates"
import CentreContainer from "@/components/Container"
import ChartContainer from "@/components/Chart/Container"
import { StickyCard } from "@/components/StickyCard"
import Intro from "@/Intro"
import IntroVideo from "@/IntroVideo"
import Tabs from "@/Tabs"
import { useTheme } from "@material-ui/core/styles"
import { setCookie } from "@/utils"
import { trackCustomEvent } from "@/utils/ga"

const COOKIE_EXPIRED_DURATION = 36000 * 2400 // 24 hours
const IndexPage = props => {
  const {
    location,
    data: {
      cms: { case_person_status, carousels, images },
    },
  } = props

  const [incidents, setIncidents] = useState([])
  const [charges, setCharges] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isShowVideo, setIsShowVideo] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const [incidentData, chargeData] = await Promise.all([
        fetch(
          `${window.location.origin}/data/incidents/summary.json`
        ).then(responses => responses.json()),
        fetch(
          `${window.location.origin}/data/charges/summary.json`
        ).then(responses => responses.json()),
      ])

      setIncidents(incidentData)
      setCharges(chargeData)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const { i18n } = useTranslation()
  const theme = useTheme()

  if (isShowVideo) {
    return (
      <Layout localePath={location.pathname}>
        <SEO />
        <IntroVideo
          isShowVideo={isShowVideo}
          onClick={() => {
            setCookie("play_intro_video", false, COOKIE_EXPIRED_DURATION)
            setIsShowVideo(false)
            trackCustomEvent("Skip", {
              event_category: "Video",
              event_label: "Intro",
            })
          }}
          onPlay={() => {
            setCookie("play_intro_video", false, COOKIE_EXPIRED_DURATION)
          }}
          onEnded={() => {
            setCookie("play_intro_video", false, COOKIE_EXPIRED_DURATION)
            setIsShowVideo(false)
          }}
        />
      </Layout>
    )
  }

  return (
    <div>
      <Layout localePath={location.pathname}>
        <div>
          <SEO />
          {
            // !isShowVideo &&
            <>
              <Intro images={images} />
              <CentreContainer>
                <LatestUpdates
                  items={carousels.map(item => ({
                    id: item.id,
                    title: withLanguage(i18n, item, "title"),
                    // imgUrl: item.cover_image,
                  }))}
                />
              </CentreContainer>
              <ChartContainer />
              <Tabs
                charges={charges}
                incidents={incidents}
                isLoading={isLoading}
                case_person_status={case_person_status}
              />
              <StickyCard backgroundColor={theme.palette.background.default} />
            </>
          }
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query {
    cms {
      case_person_status {
        id
        type_zh
        type_en
      }
      carousels {
        id
        order
        title_zh
        title_en
        link
        active
      }
      images {
        key
        image_url
      }
    }
  }
`
