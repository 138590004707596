import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import SkipNextOutlinedIcon from "@material-ui/icons/SkipNextOutlined"
import VolumeOffRoundedIcon from "@material-ui/icons/VolumeOffRounded"
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded"
import { useTranslation } from "react-i18next"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { trackCustomEvent } from "@/utils/ga"

const useStyles = makeStyles(theme => ({
  container: {
    background: "#333",
    height: "100vh",
  },
  controls: {
    zIndex: 10001,
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(1),
  },
  skipBtn: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.background.paper,
      background: theme.palette.secondary.main,
    },
  },
  muteBtn: {
    color: theme.palette.background.paper,
  },
}))
const Video = React.memo(function Video(props) {
  const { display = "show", onPlay, onEnded, isMuted } = props
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))

  useEffect(() => {
    const video = document.querySelector("video")
    video.muted = isMuted

    video.onplay = () => onPlay()
    video.onended = () => onEnded()

    // Error handling
    video.onerror = () => onEnded()
  }, [isMuted, viewport7])

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <video
            autoPlay
            ${isMuted && "muted"}
            playsinline
            style="
              display: ${display};
              z-index: 10000;
              width: 100vw;
              height: 100vh;
              object-fit: cover;
              position: fixed;
              overflow: hidden;
              left: 0;
              top: 0;
            "
        >
        <source src="/intro_${
          viewport7 ? "landscape" : "portrait"
        }.mp4" type="video/mp4" />
      `,
        }}
      />
    </>
  )
})

const IntroVideo = React.memo(function IntroVideo(props) {
  const { isShowVideo, onClick, onPlay, onEnded } = props

  const [isMuted, setIsMuted] = useState(true)
  const classes = useStyles()
  const { t } = useTranslation()

  if (isShowVideo) {
    return (
      <div className={classes.container}>
        <div className={classes.controls}>
          <IconButton
            onClick={() => {
              trackCustomEvent(isMuted ? "mute" : "unmute", {
                event_category: "Video",
                event_label: "Intro",
              })
              setIsMuted(!isMuted)
            }}
            className={classes.muteBtn}
          >
            {isMuted ? <VolumeOffRoundedIcon /> : <VolumeUpRoundedIcon />}
          </IconButton>
          <Button
            onClick={onClick}
            variant="contained"
            color="secondary"
            className={classes.skipBtn}
            startIcon={<SkipNextOutlinedIcon />}
          >
            {t("common.skip")}
          </Button>
        </div>
        <Video
          onPlay={onPlay}
          onEnded={onEnded}
          display={isShowVideo ? "show" : "none"}
          isMuted={isMuted}
        />
      </div>
    )
  }
})

export default IntroVideo
