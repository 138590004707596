import React from "react"
import SwiperCore, { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import { PlainLink } from "@/components/Link"
import { useTranslation } from "react-i18next"
import { NameCardBasicInfo } from "@/components/Profile"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { trackCustomEvent } from "@/utils/ga"

const NameCardWrapper = styled.div`
  ${({ theme }) => `

    .swiper-container {
      padding: 15px;
    }

    .swiper-slide {
      margin-top: auto;
      margin-bottom: auto;
    }

    .swiper-slide .card {
      padding: ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
    2
  )}px;
      background-repeat: no-repeat;
      background-color: #c1c1be;
      border-radius: ${theme.spacing(1)}px;
      height: 180px;
      overflow: hidden;
    }


    .swiper-slide .card.image_0 {
      background-image: url('images/head.png');
      background-position: 90% center;
      background-size: 120%;
    }
    
    .swiper-slide .card.image_1 {
      background-image: url('images/feet.png');
      background-position: 75% center;
      background-size: 120%;
    }

    .swiper-slide .card.image_2 {
      background-image: url('images/hand.png');
      background-position: 40% center;
      background-size: 120%;
    }
    
    .swiper-slide .card.image_3 {
      background-image: url('images/eye.png');
      background-position: 40% center;
      background-size: 120%;
    }


    ${theme.breakpoints.up("viewport7")} {
      .swiper-slide .card.image_0 {
        background-image: url('images/head.png');
        background-position: 100% center;
        background-size: 100%;
      }
      
      .swiper-slide .card.image_1 {
        background-image: url('images/feet.png');
        background-position: 20% center;
        background-size: 105%;
      }
  
      .swiper-slide .card.image_2 {
        background-image: url('images/hand.png');
        background-position: 30% center;
        background-size: 110%;
      }
      
      .swiper-slide .card.image_3 {
        background-image: url('images/eye.png');
        background-position: 100% center;
        background-size: 100%;
      }
    }
    
    .swiper-slide-prev .card.image_0,
    .swiper-slide-next .card.image_0,
    .swiper-slide-prev .card.image_1,
    .swiper-slide-next .card.image_1,
    .swiper-slide-prev .card.image_2,
    .swiper-slide-next .card.image_2,
    .swiper-slide-prev .card.image_3,
    .swiper-slide-next .card.image_3  {
      background-position: 25% center;
      background-size: 170%;
    }

    .swiper-slide-active {
      z-index: 1000;
    }


    ${theme.breakpoints.up("viewport7")} {
      .swiper-slide-active {
        margin-top: 40px;
      }

      .swiper-slide-active .card {
        opacity: 100%;
        width: 360px;
      }
      
      .swiper-slide-active {
        left: -80px;
      }

      .swiper-slide-next .card {
        padding-left: 50px;
      }
    }

    ${theme.breakpoints.up("viewport9")} {
      .swiper-slide-active {
        left: -36px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 43%;
        width: 64px;
        height: 64px;
        border: 1px ${theme.palette.primary.main} solid;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
    }

    ${theme.breakpoints.up("viewport7")} {
      .swiper-button-next,
      .swiper-button-prev {
        border: none;
        background-color: transparent;
        color: white;
      }
    }


    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: -36px;
        right: auto;
        border-radius: 0 64px 64px 0;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: -36px;
        left: auto;
        border-radius: 64px 0 0 64px;
    }

    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
        content: 'prev';
        position: absolute;
        right: 14px;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
        content: 'next';
        position: absolute;
        left: 14px;
    }

    ${theme.breakpoints.up("viewport7")} {
      .swiper-button-prev:after,
      .swiper-container-rtl .swiper-button-next:after {
          right: 18px;
      }


      .swiper-button-next:after,
      .swiper-container-rtl .swiper-button-prev:after {
          content: 'next';
          position: absolute;
          left: 18px;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-family: swiper-icons;
        font-size: 16px;
        text-transform: none!important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }
`}
`

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

// install Swiper components
SwiperCore.use([Navigation, A11y])

const NameCard = ({ defendants }) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))
  return (
    <NameCardWrapper>
      <Swiper
        loop={true}
        centeredSlides={true}
        spaceBetween={16}
        slidesPerView={viewport7 ? 3 : 1}
        preventClicks={true}
        navigation
        onSlideChange={() => {
          trackCustomEvent("Swipe", {
            event_category: "Name Card",
          })
        }}
      >
        {defendants.map((defendant, id) => {
          const {
            thisDefendant: [people],
          } = defendant
          return (
            <SwiperSlide key={people.person.id}>
              <PlainLink
                to={{
                  page: "template-defendant",
                  lang: i18n.language,
                  obj: people.person,
                }}
              >
                <NameCardBasicInfo defendant={defendant} id={id} />
              </PlainLink>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </NameCardWrapper>
  )
}

export default NameCard

export const NameCardPlaceholder = ({ children }) => {
  return <>{children}</>
}
