import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const DateTime = ({ date }) => {
  const [, MM, DD] = date.split("-")
  const monthMapping = {
    "01": "JAN",
    "02": "FEB",
    "03": "MAR",
    "04": "APR",
    "05": "MAY",
    "06": "JUN",
    "07": "JUL",
    "08": "AUG",
    "09": "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  }
  const classes = useStyles()
  return (
    <div className={classes.dateTime}>
      <div className={classes.month}>{monthMapping[MM]}</div>
      <div className={classes.date}>{DD.replace(/^0/, "")}</div>
    </div>
  )
}

export default DateTime

const useStyles = makeStyles(theme => ({
  dateTime: {
    fontFamily: "Oswald, Arial, sans-serif",
    marginTop: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  month: {
    fontSize: "0.7rem",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  date: {
    marginTop: theme.spacing(0.25),
    fontSize: "1.5rem",
    fontWeight: 700,
  },
}))
