import React, { useState, useMemo, useEffect, useCallback } from "react"
import { useLandingTabStyle } from "@/theme"
import { useTranslation } from "react-i18next"
import { PlainLink } from "@/components/Link"
import { withLanguage } from "@/utils/i18n"
import Legend from "@/components/Legend"
import TextField from "@material-ui/core/TextField"
import DefendantDots from "@/DefendantDots"
import CentreContainer from "@/components/Container"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Loader from "@/components/Loader"
import { trackCustomEvent } from "@/utils/ga"

const searchRegExp = /(\s|\.)/g
const replaceWith = ""

const ChargeTab = ({ charges, isLoading }) => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))
  const classes = useLandingTabStyle()

  const chargesWithPureName = useMemo(
    () =>
      charges.map(charge => ({
        ...charge,
        pureNameZh:
          (charge.name_zh &&
            charge.name_zh.replace(searchRegExp, replaceWith)) ||
          "",
        pureNameEn:
          (charge.name_en &&
            charge.name_en.replace(searchRegExp, replaceWith)) ||
          "",
      })),
    [charges]
  )
  const [textQuery, setTextQuery] = useState("")
  const [pureSearchKey, setPureSearchKey] = useState("")
  const [selectedLegendId, setSelectedLegendId] = useState(-1)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setPureSearchKey(textQuery.trim().replace(searchRegExp, replaceWith))
    }, 200)
    return () => clearTimeout(timeOutId)
  }, [textQuery])

  const legendHandleClick = useCallback(
    (clickedIndex, items) => {
      setSelectedLegendId(
        items[clickedIndex].id == selectedLegendId ? -1 : items[clickedIndex].id
      )

      trackCustomEvent("Click", {
        event_category: "Legend",
        event_label: `Landing - ${items[clickedIndex].title}`,
      })
    },
    [selectedLegendId]
  )

  return (
    <>
      <div className={classes.legendWrapper}>
        <Legend
          filterable={true}
          type="charge"
          activeId={selectedLegendId}
          handleClick={legendHandleClick}
        />
      </div>
      <CentreContainer>
        {isLoading ? (
          <Loader background="dark" />
        ) : (
          <>
            <TextField
              id="charge-search"
              className={
                viewport7 ? classes.textField : classes.textFieldMobile
              }
              placeholder={t("common.search_charge")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={e => setTextQuery(e.target.value)}
              helperText={t("charge.tab_description")}
            />
            <div>
              {chargesWithPureName.map(charge => {
                if (
                  pureSearchKey == "" ||
                  charge.pureNameZh.includes(pureSearchKey) ||
                  charge.pureNameEn.includes(pureSearchKey)
                ) {
                  const defendants = charge.defendants
                    .filter(
                      d =>
                        selectedLegendId == -1 ||
                        [selectedLegendId].includes(d.progress.id)
                    )
                    .sort((a, b) => a.progress.id - b.progress.id)

                  if (!defendants.length) {
                    return
                  }

                  return (
                    <div key={charge.id}>
                      <PlainLink
                        to={{
                          page: "template-charge",
                          lang: i18n.language,
                          obj: charge,
                        }}
                      >
                        <div className={classes.dotTitle}>
                          <span>{withLanguage(i18n, charge, "name")}</span>
                          <KeyboardArrowRightIcon />
                        </div>
                        <div className={classes.dotSubTitle}>
                          {t("common.defendant_count", {
                            count: defendants.length,
                          })}
                        </div>
                      </PlainLink>
                      <div className={classes.defendantsWrapper}>
                        <DefendantDots
                          selectedDefendants={defendants}
                          context="progress"
                          showCount={false}
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </>
        )}
      </CentreContainer>
    </>
  )
}

export default ChargeTab
