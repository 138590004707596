import React, { useState, useMemo, useEffect, useCallback } from "react"
import { useLandingTabStyle } from "@/theme"
import { useTranslation } from "react-i18next"
import { PlainLink } from "@/components/Link"
import { withLanguage } from "@/utils/i18n"
import Legend from "@/components/Legend"
import TextField from "@material-ui/core/TextField"
import { defendantColor, statusPriority } from "@/utils/defendant"
import DefendantDots from "@/DefendantDots"
import CentreContainer from "@/components/Container"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import DateTime from "@/components/DateTime"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Loader from "@/components/Loader"
import { trackCustomEvent } from "@/utils/ga"

const searchRegExp = /(\s|\.)/g
const replaceWith = ""

const IncidentRow = React.memo(function IncidentRow({
  incident,
  selectedLegendId,
}) {
  const { i18n, t } = useTranslation()
  const classes = useLandingTabStyle()

  const defendants = incident.defendants
    .map(d => {
      return {
        id: d.person.id,
        ...d,
        status: {
          ...d.status,
          ...defendantColor.case[`status${(d.status && d.status.id) || 0}`],
        },
      }
    })
    .sort((a, b) => {
      if (
        statusPriority.findIndex(s => s === a.status.id) >
        statusPriority.findIndex(s => s === b.status.id)
      ) {
        return 1
      }
      return -1
    })
    .filter(d => selectedLegendId === -1 || d.status.id === selectedLegendId)

  if (!defendants.length) return <></>

  return (
    <div className={classes["_" + incident.date.substr(0, 4)]}>
      <div className={classes.timeline} key={incident.id}>
        <div className={classes.timelineDate}>
          <div className={classes.timelineDot} />
          <DateTime date={incident.date} language={i18n.language} />
        </div>
        <div className={classes.timelineDotsWrapper}>
          <PlainLink
            to={{
              page: "template-incident",
              lang: i18n.language,
              obj: incident,
            }}
          >
            <div className={classes.dotTitle}>
              <span>{withLanguage(i18n, incident, "name")}</span>
              <KeyboardArrowRightIcon />
            </div>
            <div className={classes.dotSubTitle}>
              {t("common.defendant_count", {
                count: defendants.length,
              })}
            </div>
          </PlainLink>
          <div className={classes.defendantsWrapper}>
            <DefendantDots
              selectedDefendants={defendants}
              context="status"
              showCount={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const IncidentTab = ({ incidents, isLoading }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))

  const incidentsWithPureName = useMemo(
    () =>
      incidents.map(incident => ({
        ...incident,
        pureNameZh:
          (incident.name_zh &&
            incident.name_zh.replace(searchRegExp, replaceWith)) ||
          "",
        pureNameEn:
          (incident.name_en &&
            incident.name_en.replace(searchRegExp, replaceWith)) ||
          "",
      })),
    [incidents]
  )

  const incidentsByYear = incidentsWithPureName.reduce((acc, c) => {
    const year = c.date.split("-").shift()
    const yearObj = acc.find(a => a.year === year)

    if (!yearObj) {
      return [
        ...acc,
        {
          year,
          incident: [c],
        },
      ]
    }
    yearObj.incident.push(c)
    return acc
  }, [])
  incidentsByYear.sort((a, b) => b - a)

  const [textQuery, setTextQuery] = useState("")
  const [pureSearchKey, setPureSearchKey] = useState("")
  const [selectedLegendId, setSelectedLegendId] = useState(-1)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setPureSearchKey(textQuery.trim().replace(searchRegExp, replaceWith))
    }, 200)
    return () => clearTimeout(timeOutId)
  }, [textQuery])

  const legendHandleClick = useCallback(
    (clickedIndex, incidentLegends) => {
      setSelectedLegendId(
        incidentLegends[clickedIndex].id == selectedLegendId
          ? -1
          : incidentLegends[clickedIndex].id
      )
      trackCustomEvent("Click", {
        event_category: "Legend",
        event_label: `Landing - ${incidentLegends[clickedIndex].title}`,
      })
    },
    [selectedLegendId]
  )

  const classes = useLandingTabStyle()

  return (
    <>
      <div className={classes.legendWrapper}>
        <Legend
          filterable={true}
          type="incidents"
          activeId={selectedLegendId}
          handleClick={legendHandleClick}
        />
      </div>
      <CentreContainer>
        {isLoading ? (
          <Loader background="dark" />
        ) : (
          <>
            <TextField
              id="incident-search"
              className={
                viewport7 ? classes.textField : classes.textFieldMobile
              }
              placeholder={t("common.search_incident")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={e => setTextQuery(e.target.value)}
            />
            <div>
              {incidentsByYear.map(eachYear => {
                return (
                  <div key={eachYear.year}>
                    <div className={classes.yearTitle}>{eachYear.year}</div>
                    {eachYear.incident.map((incident, i) => {
                      if (
                        pureSearchKey == "" ||
                        incident.pureNameZh.includes(pureSearchKey) ||
                        incident.pureNameEn.includes(pureSearchKey)
                      ) {
                        return (
                          <IncidentRow
                            key={"incident_" + i}
                            incident={incident}
                            selectedLegendId={selectedLegendId}
                          />
                        )
                      }
                    })}
                  </div>
                )
              })}
            </div>
          </>
        )}
      </CentreContainer>
    </>
  )
}

export default IncidentTab
