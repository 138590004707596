import React from "react"
import SwiperCore, { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"

const NewsWrapper = styled.div`
  ${({ theme }) => `
    img {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        max-height: 233px;
    }

    .text {
        padding: 0 ${theme.spacing(2)}px;
    }

    .swiper-slide {
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: calc(var(--swiper-navigation-size)/ 44 * 27);
        height: var(--swiper-navigation-size);
        margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 10px;
        right: auto;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 10px;
        left: auto;
    }

    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
        content: 'prev';
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
        content: 'next';
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-family: swiper-icons;
        font-size: var(--swiper-navigation-size);
        text-transform: none!important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }
`}
`

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

// install Swiper components
SwiperCore.use([Navigation, A11y])

const LatestUpdates = ({ items }) => {
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))

  return (
    <NewsWrapper>
      <Swiper
        loop={true}
        centeredSlides={true}
        spaceBetween={35}
        slidesPerView={viewport7 ? 3 : 1}
        navigation
      >
        {items.map(item => {
          const { id, imgUrl, title } = item
          return (
            <SwiperSlide key={id}>
              <img src={imgUrl} />
              <div className="text">
                <Typography variant="body1">{title}</Typography>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </NewsWrapper>
  )
}

export default LatestUpdates
